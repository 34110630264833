@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Cartograph CF';
    font-weight: 100;
    src: url('./fonts/webfont/CartographCF-Thin.woff');
  }
  @font-face {
    font-family: 'Cartograph CF';
    font-weight: 300;
    src: url('./fonts/webfont/CartographCF-ExtraLight.woff');
  }
  @font-face {
    font-family: 'Cartograph CF';
    font-weight: 400;
    src: url('./fonts/webfont/CartographCF-Regular.woff');
  }
  @font-face {
    font-family: 'Cartograph CF';
    font-weight: 600;
    src: url('./fonts/webfont/CartographCF-DemiBold.woff');
  }
  @font-face {
    font-family: 'Cartograph CF';
    font-weight: 700;
    src: url('./fonts/webfont/CartographCF-Bold.woff');
  }
  @font-face {
    font-family: 'Cartograph CF';
    font-weight: 800;
    src: url('./fonts/webfont/CartographCF-ExtraBold.woff');
  }
  @font-face {
    font-family: 'Cartograph CF';
    font-weight: 900;
    src: url('./fonts/webfont/CartographCF-Heavy.woff');
  }
}
